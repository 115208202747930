import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ReactPlayer from "react-player";
import "./App.css";
import fileSaver from "file-saver";

import imgLogo from "./assets/images/logo.svg";
import imgBanner from "./assets/images/banner.svg";
import imgFooter from "./assets/images/footer.svg";
import imgTime from "./assets/images/time.svg";
import imgRotate from "./assets/images/rotate.svg";
import imgDownload from "./assets/images/download.png";
import playIcon from "./assets/images/play-btn.svg";
import screenfull from 'screenfull';

const cloudfrontUrl = "https://d23jhf5i1h83qm.cloudfront.net";

function Download() {
  const [playing, setPlaying] = useState<boolean>(false);
  const [fileExists, setFileExists] = useState<boolean | null>(null);

  const [videoUrl, setVideoUrl] = useState<string | null>(null);

  let { videoId } = useParams<{ videoId: string }>();

  useEffect(() => {
    setVideoUrl(`${cloudfrontUrl}/${videoId}.mp4`);
  }, [videoId]);

  function checkFileExists(url: string) {
    return fetch(url, {
      method: "HEAD", // file isn't downloaded to the client while checking if it exists,
      cache: "no-cache", // if it caches, we get CORS error the second time we request
    })
      .then((response) => {
        if (response.ok) {
          console.log("File exists.");
          setFileExists(true);
        } else {
          console.log("File does not exist.");
          setFileExists(false);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setFileExists(false);
      });
  }

  useEffect(() => {
    if (!videoUrl) return;

    console.log("Checking if the file exists...");

    checkFileExists(videoUrl)
      .then()
      .catch((error) => console.error(error));
  }, [videoUrl]);

  const saveVideo = useCallback(() => {
    if (!videoUrl || !fileExists) return;

    fileSaver.saveAs(videoUrl, "JoyOfMoving.mp4");
  }, [videoUrl, fileExists]);

  useEffect(()=>{
    if(playing && screenfull.isEnabled) {
      const reactPlayer  = document.querySelector('.react-player');
      if(reactPlayer) screenfull.request(reactPlayer)
    }
  },[playing]);

  return (
    <div className="wrapper">
      <div className="banner">
        <img src={imgBanner} className="banner-image" alt="banner" />
        <img src={imgLogo} className="logo" alt="logo" />
      </div>
      <div className="content-holder">
        <div className="content">
          <div className="title">DEINE COOLE PERFORMANCE</div>
          <div className="center">
            {fileExists !== null &&
              (fileExists && videoUrl ? (
                // video player
                <div
                  className="video-holder"
                  onClick={() => setPlaying(!playing)}
                >
                  <ReactPlayer
                  className='react-player'
                    allowFullScreen
                    url={videoUrl}
                    width={"100%"}
                    height={"100%"}
                    playing={playing}
                  />
                  {!playing && (
                    <div className="video-play-btn">
                      <img
                        src={playIcon}
                        alt=""
                        width={"100%"}
                        height={"100%"}
                      />
                    </div>
                  )}
                </div>
              ) : (
                <div className="image-holder">
                  <img src={imgTime} className="time-image" alt="time" />
                  <img src={imgRotate} className="rotate-image" alt="time"/>
                </div>
              ))}
          </div>
          <div className="bottom">
            <div className="description">
              {fileExists !== null &&
                (fileExists && videoUrl ? (
                  "Lade dein Video jetzt herunter und zeig es deiner Familie und Freunden."
                ) : (
                  <div className="description-text">
                    Dein Video wird gerade noch verarbeitet. Versuche es später noch einmal.
                  </div>
                ))}
            </div>
            <div>
              <button
                className="download-button"
                style={fileExists ? {} : { opacity: 0.2 }}
                onClick={saveVideo}
              >
                Download
                <img src={imgDownload} alt="download" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <img src={imgFooter} className="footer-image" alt="footer" />
    </div>
  );
}

export default Download;
