import { Route, Routes } from "react-router-dom";
import "./assets/fonts.css";
import "./App.css";
import Download from "./Download";

function App() {
  return (
    <Routes>
      <Route path="/:videoId?" element={<Download />} />
    </Routes>
  );
}

export default App;
